import React, { Fragment, useEffect } from "react";
import { $ } from "react-jquery-plugin";
import Anime from "animejs/lib/anime.es.js";
import { useCookies } from "react-cookie";

const Loading = (params) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(() => {
    if (cookies.loading) {
    } else {
      setCookie("loading", true, {});
    }

    setTimeout(function () {
      $(".wrapper__loading").addClass("close");
    }, 2000);

    Anime({
      targets: ".move__lineLoading",
      strokeDashoffset: [Anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100;
      },
      loop: true,
    });
  }, []);

  return (
    <Fragment>
      {!cookies.loading ? (
        <div className="wrapper__loading bg__darkblue w-100 h-100">
          <div className="position-relative w-100 h-100">
            <svg
              className="path__41"
              width="2560"
              height="1041"
              viewBox="0 0 2560 1041"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2313.66"
                y="589.342"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 2313.66 589.342)"
                fill="#16C79A"
                fill-opacity="0.5"
              />
              <g opacity="0.5">
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M1630.12 520.428L1552.16 520.428L1460.02 612.565L1287.09 612.565"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <rect
                  x="1296.91"
                  y="613.492"
                  width="14.4906"
                  height="14.4906"
                  transform="rotate(135 1296.91 613.492)"
                  fill="#319CC9"
                />
                <rect
                  x="1639.85"
                  y="521.718"
                  width="14.4906"
                  height="14.4906"
                  transform="rotate(135 1639.85 521.718)"
                  fill="#319CC9"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M55.2831 449.553L93.5555 487.826L277.83 487.826"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <rect
                  x="287.396"
                  y="487.906"
                  width="14.4906"
                  height="14.4906"
                  transform="rotate(135 287.396 487.906)"
                  fill="#319CC9"
                />
                <rect
                  x="62.791"
                  y="455.925"
                  width="14.4906"
                  height="14.4906"
                  transform="rotate(-180 62.791 455.925)"
                  fill="#319CC9"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2558.58 143.373L2277.92 143.373L2136.17 1.62352L1838.49 1.6235L1747.77 92.3433L1054.62 92.3433"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2555.74 918.744L2185.78 918.744L2090.81 823.772L1700.99 823.772L1661.3 863.462L1509.63 863.462L1458.6 812.432L1077.3 812.432"
                  stroke="#16C79A"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2426.76 501.999L2394.15 469.397L2209.88 469.397"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M1336.7 219.918L973.818 219.918L924.206 170.305L773.951 170.305L724.339 219.918L350.119 219.918L249.477 119.275L8.50277 119.275"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M1854.09 157.548L1946.23 157.548L1988.75 200.073L2205.63 200.073"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2302.79 589.963L2090.17 589.963L2035.53 625.322L1827.15 625.322"
                  stroke="#16C79A"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M511.717 879.054L428.084 879.054L379.89 830.859L157.343 830.859"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M1934.88 1026.47L1818.65 910.239L1380.64 910.239L1339.53 951.346L1007.84 951.346"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M5.6674 303.55L55.2798 303.55L150.252 398.522L467.771 398.522L527.306 338.988L815.058 338.988L861.835 385.765L1394.81 385.765L1454.35 445.3L1829.99 445.3L1915.04 360.25L2555.74 360.25"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M5.66842 494.912L65.2033 554.447L707.329 554.447L789.544 472.232L901.526 472.232L951.139 521.844L1186.44 521.844"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M-0.000153583 795.422L29.7673 795.422L107.73 717.459L643.543 717.46L690.321 670.682L773.953 670.682"
                  stroke="#16C79A"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M1872.51 283.705L1766.2 283.705L1710.92 338.987L1494.04 338.987"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M582.593 147.626L680.4 147.626L755.527 72.4985L878.85 72.4985"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M330.277 292.21L212.625 292.21L128.993 208.578"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M402.573 453.805L510.302 453.805L574.09 390.018L708.752 390.018"
                  stroke="#16C79A"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2280.76 978.279L2056.79 978.279L1953.31 874.801"
                  stroke="#319CC9"
                  stroke-width="1.5"
                />
                <path
                  className="move__lineLoading"
                  style={{ strokeDashoffset: "0px" }}
                  d="M2192.87 776.995L2272.25 856.374L2560 856.374"
                  stroke="white"
                  stroke-width="1.5"
                />
                <rect
                  x="161.811"
                  y="830.85"
                  width="14.4906"
                  height="14.4906"
                  transform="rotate(135 161.811 830.85)"
                  fill="white"
                />
              </g>
              <rect
                x="412.98"
                y="454.096"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 412.98 454.096)"
                fill="#208D88"
              />
              <path
                d="M1065.05 91.8344L1054.81 102.081L1044.56 91.8345L1054.81 81.5881L1065.05 91.8344Z"
                fill="#8CA2B5"
              />
              <path
                d="M1942.94 1033.13L1928.45 1033.13L1928.45 1018.64L1942.94 1018.64L1942.94 1033.13Z"
                fill="#8CA2B5"
              />
              <rect
                x="2434.42"
                y="509.059"
                width="14.4906"
                height="14.4906"
                transform="rotate(-180 2434.42 509.059)"
                fill="#95A9BB"
              />
              <rect
                x="2209.23"
                y="478.832"
                width="14.4906"
                height="14.4906"
                transform="rotate(-135 2209.23 478.832)"
                fill="#95A9BB"
              />
              <rect
                x="2200.15"
                y="784.379"
                width="14.4906"
                height="14.4906"
                transform="rotate(-180 2200.15 784.379)"
                fill="#95A9BB"
              />
              <rect
                x="2214.64"
                y="200.512"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 2214.64 200.512)"
                fill="#25709A"
              />
              <rect
                x="1864.45"
                y="157.04"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1864.45 157.04)"
                fill="#25709A"
              />
              <rect
                x="1883.77"
                y="285.04"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1883.77 285.04)"
                fill="#25709A"
              />
              <rect
                x="2291.92"
                y="978.172"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 2291.92 978.172)"
                fill="#25709A"
              />
              <rect
                x="340.527"
                y="292.285"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 340.527 292.285)"
                fill="#8CA2B5"
              />
              <rect
                x="137.66"
                y="216.833"
                width="14.4906"
                height="14.4906"
                transform="rotate(-180 137.66 216.833)"
                fill="#8CA2B5"
              />
              <rect
                x="1961.06"
                y="880.983"
                width="14.4906"
                height="14.4906"
                transform="rotate(-180 1961.06 880.983)"
                fill="#25709A"
              />
              <rect
                x="1504.6"
                y="338.172"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1504.6 338.172)"
                fill="#25709A"
              />
              <rect
                x="888.754"
                y="72.5112"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 888.754 72.5112)"
                fill="#25709A"
              />
              <rect
                x="591.699"
                y="147.379"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 591.699 147.379)"
                fill="#25709A"
              />
              <rect
                x="1086.79"
                y="811.53"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1086.79 811.53)"
                fill="#178682"
              />
              <rect
                x="784.906"
                y="671.455"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 784.906 671.455)"
                fill="#208D88"
              />
              <rect
                x="719.699"
                y="391.304"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 719.699 391.304)"
                fill="#208D88"
              />
              <rect
                x="1354.87"
                y="219.831"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1354.87 219.831)"
                fill="#8CA2B5"
              />
              <rect
                x="1205.13"
                y="521.718"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1205.13 521.718)"
                fill="#8CA2B5"
              />
              <rect
                x="531.32"
                y="879.151"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 531.32 879.151)"
                fill="#8CA2B5"
              />
              <rect
                x="1011.92"
                y="951.604"
                width="14.4906"
                height="14.4906"
                transform="rotate(135 1011.92 951.604)"
                fill="#8CA2B5"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Loading;
