import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Loadable, { lazy } from "react-loadable";

import LoadingStart from "./Loading";

// import Homepage from "./homepage/index";
// import Dev from "./dev/index";
// import Apply from "./apply/index";
// import About from "./about/index";

const svgLoading = function (params) {
  return (
    <div className="wrapper__loading bg__darkblue text-center d-flex justify-content-center align-items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          display: "block",
          shapeRendering: "auto",
        }}
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.9166666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(30 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.8333333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(60 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.75s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.6666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(120 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5833333333333334s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(150 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.4166666666666667s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(210 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.3333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(240 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.25s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.16666666666666666s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(300 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.08333333333333333s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(330 50 50)">
          <rect
            x="47"
            y="24"
            rx="3"
            ry="6"
            width="6"
            height="12"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </div>
  );
};

const Homepage = Loadable({
  loader: () => import("./homepage/index"),
  loading: () => svgLoading,
});

const Dev = Loadable({
  loader: () => import("./dev/index"),
  loading: () => svgLoading,
});

const Apply = Loadable({
  loader: () => import("./apply/index"),
  loading: () => svgLoading,
});

const About = Loadable({
  loader: () => import("./about/index"),
  loading: () => svgLoading,
});

const Login = Loadable({
  loader: () => import("./auth/login"),
  loading: () => svgLoading,
});

const Dash = Loadable({
  loader: () => import("./dashboard/index"),
  loading: () => svgLoading,
});

const Term = Loadable({
  loader: () => import("./term/index"),
  loading: () => svgLoading,
});

const Policy = Loadable({
  loader: () => import("./policy/index"),
  loading: () => svgLoading,
});

const GamePrivacy = Loadable({
  loader: () => import("./gamePrivacy/index"),
  loading: () => svgLoading,
});

const index = (props) => {
  const login = localStorage.getItem("isLoggedIn");
  return (
    <Fragment>
      <LoadingStart />
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route path="/indie-developers">
            <Dev />
          </Route>
          <Route path="/apply">
            <Apply />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/login">
            {login ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route path="/dashboard">
            {login ? <Dash /> : <Redirect to="/login" />}
          </Route>
          <Route path="/terms-of-use">
            <Term />
          </Route>
          <Route path="/privacy-policy">
            <Policy />
          </Route>
          <Route path="/game-privacy">
            <GamePrivacy />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

export default index;
